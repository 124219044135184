.page_main_content {
  padding: 30px;
  height: calc(100% - 85px);

  .page_inner_content {
    @include themify($themes) {
      border: 1px solid themed("borderColor");
    }

    border-radius: 10px;
    padding: 20px;
    min-height: 100%;
    max-height: 100%;
    overflow: hidden auto;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.theme-dark {
  .ant-form-item {
    &.bg_light_red {
      .ant-form-item-row {
        .ant-form-item-control {
          .ant-input {
            background: rgb(255 0 0 / 15%) !important;
          }
        }
      }
    }
  }
}

.theme-light {
  .ant-form-item {
    &.bg_light_red {
      .ant-form-item-row {
        .ant-form-item-control {
          .ant-input {
            background: rgb(255 0 0 / 15%) !important;
          }
        }
      }
    }
  }
}

.page_header {
  padding: 0 0 20px;
  margin-bottom: 15px;

  @include themify($themes) {
    border-bottom: 1px solid themed("borderColor");
  }
}

.user_main_wrap {
  // height: calc(100vh - 390px);
  overflow: hidden auto;

  .user_box {
    padding: 15px 0;
    position: relative;

    @include themify($themes) {
      border-bottom: 1px solid themed("borderColor");
    }

    .user_img {
      width: 60px;
      height: 60px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }

      .user_name_letter {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e5ebf0;
        border-radius: 50%;
        font-size: 24px;
        font-weight: 600;
      }

      @media (max-width: 575px) {
        width: 50px;
        height: 50px;

        @media (max-width: 480px) {
          width: 40px;
          height: 40px;
        }
      }
    }

    .user_text {
      padding-left: 20px;
      width: calc(100% - 60px);

      .bedge {
        display: none;

        @media (max-width: 767px) {
          display: inline-block;

          @media (max-width: 575px) {
            font-size: 14px;
            padding: 4px 12px;

            @media (max-width: 480px) {
              font-size: 10px;
              padding: 2px 8px;
            }
          }
        }
      }

      h3 {
        @media (max-width: 575px) {
          font-size: 16px;
          line-height: 22px;

          @media (max-width: 480px) {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }

      p {
        @media (max-width: 480px) {
          font-size: 12px;
          line-height: 18px;
        }
      }

      @media (max-width: 480px) {
        padding-left: 10px;
        width: calc(100% - 40px);
      }
    }

    .user_roll {
      li {
        padding-right: 15px;
      }

      @media (max-width: 767px) {
        li.btn_delete {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
          padding-right: 0;

          &.btn_edit {
            right: 30px;
          }
        }
      }
    }
  }
}

.roll_permission_inner {
  ul.roll_checkbox_wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      flex-grow: 1;
      padding: 0 12px;

      &:last-child {

        // margin-left: auto;
        // text-align: right;
        @media (max-width: 767px) {
          // display: none;
        }
      }

      @media (max-width: 1500px) {
        padding: 0 7px;

        // flex-grow: 0;
        @media (max-width: 767px) {
          width: 25%;

          @media (max-width: 575px) {
            width: 33.33%;

            @media (max-width: 400px) {
              width: 50%;
            }
          }
        }
      }
    }
  }
}

// react-calendar__navigation__arrow react-calendar__navigation__prev-button"
.form_process_wrapper {
  .ant-form-item {
    .ant-form-item-row {
      display: block;

      .ant-form-item-label {
        text-align: start;
      }

      .ant-form-item-control {
        .ant-input {
          padding: 12px 12px;
        }
      }
    }
  }

  .ant-form-item .ant-form-item-row {
    :has(.ant-form-item-required) {
      .ant-form-item-control {
        .ant-input {
          border: 1px solid #ff4d4f !important;
        }
      }
    }

    .ant-form-item-label label {
      font-size: 14px;
      color: $textLight;
      white-space: nowrap;

      &::after {
        visibility: hidden;
      }

      span {
        b {
          color: #ff4d4f;
          font-size: 12px;
          margin-left: 1px;
        }
      }

      .ant-form-item-tooltip {
        color: $textLight;
      }
    }
  }
}

// .ant-form-item .ant-form-item-row:has(.ant-form-item-required) {
//   .ant-form-item-control {
//     .ant-input {
//       border: 1px solid #ff4d4f !important;
//     }

//     .ant-select {
//       .ant-select-selector {
//         border: 1px solid #ff4d4f !important;
//       }
//     }

//     .ant-picker {
//       border: 1px solid #ff4d4f !important;
//     }
//   }
// }

.form_process_wrapper {
  padding-left: 34px;
  position: relative;

  .form_info_wrapper {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: -28px;
      top: 5px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #c9d5df;
      box-shadow: 0 0 0 6px rgba(201, 213, 223, 0.5);
      z-index: 2;
      transition: background 2s ease;
    }

    &:after {
      content: "";
      position: absolute;
      left: -22px;
      top: 10px;
      width: 1px;
      height: calc(100% - 10px);
      background: $textLight;
      z-index: 1;
    }

    &.filled {
      &:before {
        @include themify($themes) {
          background: themed("primary");
        }

        box-shadow: 0 0 0 6px rgba(211, 180, 116, 0.5);
      }

      &:after {
        @include themify($themes) {
          background: themed("primary");
        }
      }
    }

    &.center {
      &:before {
        top: 10px;
      }
    }

    .double_column_wrapper {
      display: flex;
      gap: 60px;

      .double_column_inner {
        width: 100%;

        &.double_column_scale {
          width: 48%;
        }

        &.right_column_none {
          display: none;
        }

        &.right_column_show {
          display: block;
        }
      }
    }

    .return_btn_wrap {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}

.Guidance_wrap {
  margin-top: -15px !important;
}

.ant-upload-wrapper .ant-upload {
  width: 100%;
}

.disabledInput {
  opacity: 0.6;
  pointer-events: none;
}

.location_select {
  .custom_select {
    .ant-row {
      align-items: center;
    }
  }
}

.location_header.custom_select {
  .ant-select {
    .ant-select-selector {
      padding: 0px 16px;
      border-radius: 6px;
      width: 145px;
    }

    .ant-select-arrow:after {
      left: 9px;
      top: -11px;
    }
  }
}

.location_select {
  h5 {
    width: 100px;
    margin: 0;
  }

  .custom_select {
    width: calc(100% - 100px);
  }
}

.with_validation_label {
  font-size: 14px;
  color: #96a0b5;
  white-space: nowrap;
  padding: 0 0 8px;
  position: relative;

  span {
    color: #ff4d4f;
    position: absolute;
    right: -10px;
    top: 2px;
  }
}

.error_line {
  border: 2px solid #ff4d4f;
  border-radius: 7px;
}

.Guidance_wrap {
  .custom_select .ant-select {
    .ant-select-selector {
      padding: 0px 12px;
      border-radius: 7px;
    }

    .ant-select-arrow:after {
      top: -11px;
    }
  }
}

.filter_row_wrapper>* {
  padding: 0 8px;
}

.red_row_wrapper {
  .ant-form-item {
    .ant-form-item-row {
      .ant-form-item-control {

        .ant-select-selector,
        .ant-picker,
        .ant-input {
          border-color: #f00 !important;
        }
      }
    }
  }
}

.Principle_method_wrap {
  padding: 18px;
  border-radius: 12px;

  @include themify($themes) {
    border: 1px solid themed("borderColor");
  }
}

.default_value_wrap {
  span {
    display: inline-block;
    width: 100%;
    padding: 10px 15px;
    padding: 12px;
    border-radius: 10px;
    white-space: nowrap;

    @include themify($themes) {
      background: themed("bgLight");
      color: themed("textDark");
    }
  }
}

.main_div_wrapper {
  position: relative;
}

.img_box_wrap {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_div_wrapper .img_box_wrap img {
  display: inline-block;
  width: 20px;
}

.repeat_test_btn {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
}

.custom_default_value {
  min-width: 200px;
}

.custom_table_wrap {
  overflow: hidden;
  border-radius: 12px;

  @include themify($themes) {
    border: 1px solid themed("borderColor");
  }

  table {
    thead {
      tr {
        th {
          padding: 18px 22px;
          white-space: nowrap;

          @include themify($themes) {
            background: themed("bgLight");
            color: themed("textDark");
            border-bottom: 1px solid themed("borderColor");
          }
        }
      }
    }

    tbody {
      tr {
        td {
          color: #96a0b5;
          padding: 12px 22px;
          white-space: nowrap;

          @include themify($themes) {
            border-bottom: 1px solid themed("borderColor");
          }

          input {
            width: 120px !important;
          }
        }

        &:last-child {
          td {
            @include themify($themes) {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}

.table_custom_footer {
  padding: 12px 22px;
  border-radius: 12px;

  input {
    width: 120px !important;
  }

  @include themify($themes) {
    border: 1px solid themed("borderColor");
  }
}

// ====================================================================================
.page_main_content {
  .page_inner_content {
    .home_card_partition {
      .home_card_item {
        padding: 0 6px;
        cursor: pointer;

        &:first-child {
          .card_item {
            @include themify($themes) {
              background-color: themed("lightOrange");
              color: themed("textDark");
            }
          }
        }

        &:nth-child(2) {
          .card_item {
            @include themify($themes) {
              background-color: themed("lightGreens");
              color: themed("textDark");
            }
          }
        }

        &:nth-child(3) {
          .card_item {
            @include themify($themes) {
              background-color: themed("blueLight");
              color: themed("textDark");
            }
          }
        }

        &:nth-child(4) {
          .card_item {
            @include themify($themes) {
              background-color: themed("lightPurples");
              color: themed("textDark");
            }
          }
        }

        .card_item {
          padding: 26px;
          border-radius: 20px;
          position: relative;

          .card_number {
            h2 {
              font-size: 34px;
              line-height: 46px;
              font-weight: 700;

              @include themify($themes) {
                color: themed("textDark");
              }
            }
          }

          .card_description {
            padding-left: 22px;

            .main_title {
              font-size: 18px;
              line-height: 25px;
              padding-bottom: 4px;
              font-weight: 600;

              @include themify($themes) {
                color: themed("blackBg");
              }
            }

            .sub_title {
              font-size: 15px;
              line-height: 22px;
              font-weight: 400;

              @include themify($themes) {
                color: themed("greyLight");
              }
            }
          }

          .right_arrow_image {
            position: absolute;
            top: 13px;
            right: 13px;
            font-size: 18px;

            .orange-arrow {
              @include themify($themes) {
                color: themed("orangeArrow");
              }
            }

            .green-arrow {
              @include themify($themes) {
                color: themed("greenArrow");
              }
            }

            .blue-arrow {
              @include themify($themes) {
                color: themed("blueArrow");
              }
            }

            .purple-arrow {
              @include themify($themes) {
                color: themed("purpleArrow");
              }
            }
          }
        }
      }
    }

    .home_list_area {
      padding-top: 22px;

      .patient_list {
        .home_patient_list {
          .home_patient_list_title {
            h3 {
              font-size: 20px;
              line-height: 32px;
              font-weight: 600;
              padding-bottom: 12px;
            }

            .ant-select-show-search {
              width: 200px;
              height: auto;
            }
          }
        }
      }

      .schedule {
        .home_calendar_title {
          h3 {
            font-size: 20px;
            line-height: 32px;
            font-weight: 600;
            padding-bottom: 12px;
          }
        }

        .schedule_partition {
          padding: 10px;
          padding-right: 6px;
          border-radius: 12px;

          @include themify($themes) {
            border: 1px solid themed("borderColor");
          }

          .home_calendar {
            padding-right: 16px;
            height: 396px;

            .date_calendar {
              width: auto;
              border: none;

              @include themify($themes) {
                // border: 1px solid themed("borderColor");
                background-color: themed("whiteBg");
                color: themed("calendarBlack");
              }

              // .react-calendar__viewContainer,
              .react-calendar__viewContainer {

                .react-calendar__year-view,
                .react-calendar__decade-view,
                .react-calendar__century-view,
                .react-calendar__month-view {
                  .react-calendar__month-view__weekdays {
                    .react-calendar__month-view__weekdays__weekday {
                      abbr {
                        text-decoration: none;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22px;
                      }
                    }
                  }

                  .react-calendar__decade-view__years,
                  .react-calendar__year-view__months,
                  .react-calendar__century-view__decades,
                  .react-calendar__month-view__days {
                    .react-calendar__tile {
                      border-radius: 20px;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 22px;

                      &.react-calendar__month-view__days__day--neighboringMonth {
                        color: #858585;
                      }

                      &.react-calendar__tile--hover {
                        background-color: #d3b37496;
                        color: #fff !important;
                      }
                    }

                    .react-calendar__tile--hasActive {
                      background-color: #d3b474;
                      color: #fff;
                      border-radius: 20px;
                    }

                    .react-calendar__tile {
                      &:hover {
                        background-color: #d3b474;
                        color: #fff;
                        border-radius: 20px;
                      }
                    }

                    .react-calendar__decade-view__years__year {
                      &.react-calendar__tile--hasActive {
                        background-color: #d3b474;
                        color: #fff;
                        border-radius: 20px;
                      }
                    }

                    .react-calendar__month-view__days__day {
                      @include themify($themes) {
                        color: themed("calendarBlack");
                      }

                      &:hover {
                        background-color: #d3b474;
                        color: #fff !important;
                      }

                      &.react-calendar__tile--now {
                        background-color: #d7d7d7;
                        color: #fff;
                        border-radius: 20px;

                        &.react-calendar__tile--hasActive {
                          background-color: #d3b474 !important;
                          color: #fff;
                          border-radius: 20px;
                        }
                      }

                      &.react-calendar__tile--active,
                      &.react-calendar__tile--range {
                        background-color: #d3b474 !important;
                        color: #fff;
                        border-radius: 20px;
                      }
                    }
                  }
                }
              }

              .react-calendar__navigation {
                .react-calendar__navigation__label {

                  .react-calendar__navigation__label__labelText--from,
                  .react-calendar__navigation__label__labelText--from,
                  .react-calendar__navigation__label__labelText {
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 32px;

                    @include themify($themes) {
                      color: themed("calendarBlack");
                    }
                  }

                  &:hover {
                    background-color: transparent;
                  }

                  &:focus {
                    background-color: transparent;
                  }
                }

                .react-calendar__navigation__arrow {

                  &.react-calendar__navigation__prev-button,
                  &.react-calendar__navigation__next-button {
                    font-size: 26px;

                    @include themify($themes) {
                      color: themed("blackBg");
                    }

                    &:hover {
                      background-color: transparent;
                    }

                    &:focus {
                      background-color: transparent;
                    }
                  }

                  &.react-calendar__navigation__prev2-button,
                  &.react-calendar__navigation__next2-button {
                    display: none;
                  }
                }
              }
            }
          }

          .user_list {
            height: 380px;
            overflow: auto;
            padding-left: 16px;

            @include themify($themes) {
              border-left: 1px solid themed("borderColor");
            }

            .user_information {
              padding: 10px;

              @include themify($themes) {
                border-bottom: 1px solid themed("borderColor");
              }

              .user_information_partioton {
                .user_logo {
                  .user_logo_image {
                    height: 44px;
                    width: 44px;
                  }

                  // h2 {
                  //   font-size: 20px;
                  //   line-height: 27px;
                  //   font-weight: 700;
                  //   padding: 9px 18px;
                  //   border-radius: 50%;
                  //   @include themify($themes) {
                  //     background-color: themed("brightGray");
                  //   }
                  // }
                }

                .user_description {
                  padding-left: 16px;

                  .user_name {
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 400px;
                    padding-bottom: 4px;

                    @include themify($themes) {
                      color: themed("scheduleText");
                    }
                  }

                  .pickup_time {
                    .pickup_time_icon {
                      height: 12px;
                      width: fit-content;
                      display: flex;
                      align-items: center;
                      padding-right: 4px;
                    }

                    .user_pickup_time {
                      font-size: 14px;
                      line-height: 19px;
                      font-weight: 400px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Calendar Css
.react-calendar__tile {
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 9px !important;

  @include themify($themes) {
    color: themed("blackBg");
  }

  abbr {
    @include themify($themes) {
      color: themed("blackBg");
    }
  }

  &.react-calendar__tile--now {
    background-color: #d7d7d7;
    color: #fff;
    border-radius: 20px;

    &.react-calendar__tile--hasActive {
      background-color: #d3b474 !important;
      color: #fff;
      border-radius: 20px;
    }
  }
}

.schedule_partition {
  button {
    &:disabled {
      background-color: transparent;
    }
  }
}

.data-found {
  height: 100%;

  .no-data-fount {
    height: 250px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.home_patient_list {
  .cmn_table_wrap {
    table {
      tbody {
        tr {
          cursor: pointer;
        }
      }
    }
  }
}

.loading_table {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  @include themify($themes) {
    border: 1px solid themed("borderColor");
  }
}

.schedule_loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// responsive css start

@media (max-width: 1700px) {
  .repeat_test_btn {
    right: 15px;
    top: 15px;
  }

  .default_value_wrap span {
    padding: 9px 12px;
  }

  .form_process_wrapper .ant-form-item .ant-form-item-row .ant-form-item-label label {
    font-size: 13px;
  }

  .form_process_wrapper .ant-form-item .ant-form-item-row .ant-form-item-control .ant-input {
    padding: 9px 12px;
  }

  .patient_info_custom_row .patient_info_left {
    width: 60% !important;
  }

  .patient_info_custom_row .patient_info_right {
    width: 40% !important;
  }

  .page_main_content {
    padding: 15px;
  }

  .page_main_content .page_inner_content {
    padding: 15px;
  }

  .form_process_wrapper {
    padding-left: 35px;
  }

  .user_main_wrap {
    height: calc(100vh - 360px);
  }

  .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 16px;
  }

  .page_main_content .page_inner_content .home_card_partition .home_card_item .card_item {
    padding: 18px 8px;
  }

  .page_main_content .page_inner_content .home_card_partition .home_card_item .card_item .card_number {
    font-size: 25px;
  }

  .page_main_content .page_inner_content .home_card_partition .home_card_item .card_item .card_description {
    padding-left: 8px;
  }

  .page_main_content .page_inner_content .home_card_partition .home_card_item .card_item .card_description .main_title,
  .page_main_content .page_inner_content .home_card_partition .home_card_item .card_item .card_description .sub_title {
    font-size: 16px;
    line-height: 18px;
  }

  .page_main_content .page_inner_content .home_card_partition .home_card_item .card_item .card_description .main_title {
    padding-bottom: 5px;
  }
}

@media (max-width: 1570px) {

  // Calendar Css
  .react-calendar__month-view__weekdays__weekday,
  .react-calendar__tile {
    // border-radius: 20px;
    // font-size: 14px;
    // font-weight: 400;
    // line-height: 22px;
    padding: 9px 0px !important;
    // @include themify($themes) {
    //   color: themed("blackBg");
    // }
    // abbr {
    //   @include themify($themes) {
    //     color: themed("blackBg");
    //   }
    // }
    // &.react-calendar__tile--now {
    //   background-color: #d7d7d7;
    //   color: #fff;
    //   border-radius: 20px;
    //   &.react-calendar__tile--hasActive {
    //     background-color: #d3b474 !important;
    //     color: #fff;
    //     border-radius: 20px;
    //   }
    // }
  }
}

@media (max-width: 1552px) {
  .page_main_content .page_inner_content .home_card_partition .home_card_item .card_item .card_number h2 {
    font-size: 25px;
  }

  // .page_main_content
  //   .page_inner_content
  //   .home_card_partition
  //   .home_card_item
  //   .card_item
  //   .card_description
  //   .main_title,
  // .page_main_content
  //   .page_inner_content
  //   .home_card_partition
  //   .home_card_item
  //   .card_item
  //   .card_description
  //   .sub_title {
  //   font-size: 16px;
  //   line-height: 18px;
  // }
}

@media (max-width: 1501px) {
  .default_value_wrap span {
    border-radius: 7px;
    font-size: 13px;
    line-height: 18px;
  }

  .form_process_wrapper .ant-form-item .ant-form-item-row .ant-form-item-control .ant-input {
    padding: 9px 12px;
    font-size: 13px;
    line-height: 18px;
    border-radius: 7px;
  }

  .ant-form-item .ant-picker .ant-picker-input input {
    font-size: 12px;
  }

  .form_process_wrapper .ant-form-item {
    margin-bottom: 15px;
  }

  .form_process_wrapper .react-tel-input .form-control {
    padding: 5px 15px 5px 60px;
    border-radius: 7px;
  }

  .form_process_wrapper .form_info_wrapper:after {
    height: calc(100% - 3px);
  }

  .form_process_wrapper .form_info_wrapper:before {
    width: 10px;
    height: 10px;
    box-shadow: 0 0 0 5px rgba(201, 213, 223, 0.5);
  }

  .form_process_wrapper .form_info_wrapper.filled:before {
    box-shadow: 0 0 0 5px rgba(211, 180, 116, 0.5);
  }

  .patient_info_custom_row .patient_info_left {
    width: 55% !important;
  }

  .patient_info_custom_row .patient_info_right {
    width: 45% !important;
  }

  h3,
  .h3 {
    font-size: 14px;
    line-height: 22px;
  }

  h4,
  .h4 {
    font-size: 14px;
    line-height: 18px;
  }

  .custom_select .ant-select .ant-select-arrow:after {
    width: 18px;
    height: 18px;
    background-size: 18px;
  }

  .page_main_content {
    height: calc(100% - 65px);
  }

  .cmn_table_wrap .ant-table-wrapper .ant-table .ant-table-container table thead th {
    padding: 12px 16px;
    font-size: 13px;
  }

  .cmn_table_wrap .ant-table-wrapper .ant-table .ant-table-container table tbody td {
    padding: 12px 16px;
    font-size: 13px;
  }

  .user_main_wrap {
    height: calc(100vh - 315px);
  }

  .user_main_wrap .user_box .user_img {
    width: 50px;
    height: 50px;
  }

  .location_header.custom_select .ant-select .ant-select-selector {
    width: 130px;
  }

  .btn_primary,
  .btn_gray,
  .btn_border,
  .btn_print {
    padding: 7px 13px !important;
    font-size: 13px;
  }

  .page_header {
    padding: 0 0 15px;
    margin-bottom: 6px;
  }

  .mb24 {
    margin-bottom: 15px;
  }
}

@media (max-width: 1483px) {

  // .page_main_content
  //   .page_inner_content
  //   .home_card_partition
  //   .home_card_item
  //   .card_item
  //   .card_description
  //   .main_title,
  .page_main_content .page_inner_content .home_card_partition .home_card_item .card_item .card_description .sub_title {
    font-size: 14px;
  }
}

@media (min-width: 1400px) and (max-width: 1440px) {
  .page_main_content {
    .page_inner_content {
      .home_list_area {
        .schedule {
          .schedule_partition {
            .home_calendar {
              padding-right: 5px;
            }

            .user_list {
              padding-left: 5px;

              .user_information {
                padding: 5px;
              }
            }
          }
        }
      }
    }
  }

  .page_main_content .page_inner_content .home_list_area .schedule .schedule_partition .home_calendar .date_calendar .react-calendar__viewContainer .react-calendar__year-view .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr,
  .page_main_content .page_inner_content .home_list_area .schedule .schedule_partition .home_calendar .date_calendar .react-calendar__viewContainer .react-calendar__decade-view .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr,
  .page_main_content .page_inner_content .home_list_area .schedule .schedule_partition .home_calendar .date_calendar .react-calendar__viewContainer .react-calendar__century-view .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr,
  .page_main_content .page_inner_content .home_list_area .schedule .schedule_partition .home_calendar .date_calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr {
    font-size: 10px;
  }
}

@media (max-width: 1458px) {
  .user_main_wrap .user_box .user_roll li {
    padding-right: 0px;
  }
}

@media (max-width: 1400px) {
  .photo_upload_inner .row {
    flex-direction: row;
  }

  .page_main_content .page_inner_content .home_card_partition .home_card_item .card_item .right_arrow_image {
    font-size: 16px;
  }
}

@media (max-width: 1399px) {
  .theme-light .page_main_content .page_inner_content .home_list_area .schedule .schedule_partition .user_list {
    border: 0px;
    padding-left: 0;
  }

  .page_main_content .page_inner_content .home_list_area .schedule .schedule_partition .home_calendar {
    height: auto;
  }

  .page_main_content {
    .page_inner_content {
      .home_list_area {
        .schedule {
          .schedule_partition {
            .home_calendar {
              padding-right: 0;
            }

            .user_list {
              padding-left: 0px;
              border: none !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1373px) {
  .page_main_content .page_inner_content .home_card_partition .home_card_item .card_item .card_description .sub_title {
    padding-bottom: 0;
  }

  .page_main_content .page_inner_content .home_card_partition .home_card_item .card_item .card_description .sub_title {
    font-size: 12px;
  }

  .page_main_content .page_inner_content .home_card_partition .home_card_item .card_item .card_number h2 {
    font-size: 20px;
  }
}

@media (max-width: 1366px) {
  .patient_info_custom_row .patient_info_left {
    width: 50% !important;
  }

  .patient_info_custom_row .patient_info_right {
    width: 50% !important;
  }
}

@media (max-width: 1279px) {
  .main_wrapper {
    &.sidebar_collapse {
      .inner_wrapper {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          z-index: 99;
        }
      }
    }
  }
}

@media (max-width: 1120px) {
  .patient_info_custom_row .patient_info_left {
    width: 100% !important;
  }

  .patient_info_custom_row .patient_info_right {
    width: 60% !important;
  }
}

@media (max-width: 991px) {
  .repeat_test_btn {
    position: static;
    margin-bottom: 10px;
  }

  .page_main_content {
    padding: 0 20px 20px;
    height: calc(100% - 146px);

    .page_inner_content {
      padding: 0;
      border: 0 !important;
    }
  }

  .page_heading {
    h1 {
      text-align: center;
      margin: 0;
      font-size: 20px;
      line-height: 30px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .patient_info_custom_row .patient_info_right {
    width: 70% !important;
  }

  .home_card_partition {
    gap: 10px 0px;
  }

  .home_list_area {
    .patient_list {
      padding-bottom: 15px;
    }
  }

  .user_list {
    .data-found {
      border-left: 1px solid #475467 !important;
    }
  }

  .loading_table {
    height: 300px;
  }
}

@media (max-width: 767px) {
  .plus_mobile_wrap .btn_primary {
    min-width: auto;
    padding: 8px;
  }

  .btn_border,
  .btn_primary,
  .btn_gray,
  .btn_print {
    min-width: 80px;
  }

  .patient_info_custom_row .patient_info_right {
    width: 100% !important;
  }

  .page_main_content {
    .page_inner_content {
      .home_list_area {
        .schedule {
          .schedule_partition {
            .user_list {
              border-top: 1px solid #475467 !important;

              .data-found {
                border-left: none !important;
              }
            }
          }
        }
      }
    }
  }

  .page_main_content .page_inner_content .home_card_partition .home_card_item .card_item .card_number {
    max-width: 40px;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 575px) {
  .form_process_wrapper .ant-form-item .ant-form-item-row .ant-form-item-label label {
    height: auto;
  }

  .form_process_wrapper .ant-form-item .ant-form-item-row .ant-form-item-label {
    padding: 0 0 6px;
  }
}

@media (max-width: 481px) {
  header {
    padding: 15px;
  }

  .search_bar_mobile {
    padding: 15px;
  }

  .page_main_content {
    padding: 0 15px 15px;
    height: calc(100% - 126px);
  }

  .form_process_wrapper {
    padding-left: 25px;
  }

  .form_process_wrapper .form_info_wrapper:before {
    left: -20px;
    top: 7px;
    width: 10px;
    height: 10px;
  }

  .form_process_wrapper .form_info_wrapper:after {
    left: -15px;
    top: 10px;
    height: calc(100% - 6px);
  }

  .form_process_wrapper .form_info_wrapper:before {
    box-shadow: 0 0 0 5px rgba(201, 213, 223, 0.5);
  }

  .form_process_wrapper .form_info_wrapper.filled:before {
    box-shadow: 0 0 0 5px rgba(211, 180, 116, 0.5);
  }

  .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 16px;
  }

  .ant-form-item-control .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }

  .btn_border,
  .btn_primary,
  .btn_gray,
  .btn_print {
    min-width: auto;
    padding: 8px 15px;
    font-size: 12px;
  }

  .home_list_area {
    .home_patient_list {
      .home_patient_list_title {
        h3 {
          font-size: 16px !important;
        }
      }
    }
  }

  .table_heading {
    h3 {
      font-size: 16px !important;
    }
  }
}

@media (max-width: 375px) {
  .page_main_content .page_inner_content .home_list_area .schedule .schedule_partition {
    padding: 5px;
  }

  .date_calendar {
    .react-calendar__navigation__label {
      span {
        font-size: 15px !important;
      }
    }

    .react-calendar__tile,
    .react-calendar__month-view__weekdays__weekday abbr {
      font-size: 12px !important;
    }
  }

  .page_main_content {
    .page_inner_content {
      .home_card_partition {
        .home_card_item {
          padding: 0px;
        }
      }

      .home_list_area {

        .patient_list,
        .schedule {
          padding: 0;
        }

        .patient_list {
          padding-bottom: 20px;

          .home_patient_list {
            .home_patient_list_title {
              .ant-select-show-search {
                width: 120px;
              }
            }
          }
        }

        .schedule {
          .user_list {
            height: 300px;

            .user_information {
              padding: 10px 0px !important;

              .user_information_partioton {
                .user_logo {
                  .user_logo_image {
                    height: 34px !important;
                    width: 34px !important;
                  }
                }
              }
            }

            .data-found {
              .no-data-fount {
                height: 180px;
              }
            }
          }
        }
      }
    }
  }
}